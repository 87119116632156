import $ = require("jquery");
import LsLogger from "~/Src/Components/Logging/Logger";
import { LsRetry, ILsRetryOptions } from "~/Src/Components/Utilities/Retry";
import "./LiveChat.scss";

class LsLiveChatOptions {
    public static livePersonSelector = ".LPMcontainer";
}

export class LsLiveChat {
    protected static initialized = false;
    protected static $livePerson: JQuery;
    protected static retryOptions: ILsRetryOptions;
    protected static livePersonJsInit = false;

    public static _window: any;
    public static get window() {
        return LsLiveChat._window || (LsLiveChat._window = window);
    }

    public constructor() {
        if (!LsLiveChat.initialized) {

            LsLiveChat.retryOptions = {
                condition: this.getLivePerson,
                success: this.showLivePerson,
                failure: this.logError,
                maxTime: 5000,
            };

            $(document).on("click", '[data-action="livechat"]', e => {
                e.preventDefault();

                if (!LsLiveChat.livePersonJsInit) {
                    LsLiveChat.initLivePersonJsAsync().then(() => {
                        LsLiveChat.livePersonJsInit = true;

                        new LsRetry(LsLiveChat.retryOptions);
                    });
                } else {
                    new LsRetry(LsLiveChat.retryOptions);
                }
            });

            LsLiveChat.initialized = true;
        }
    }

    protected static initLivePersonJsAsync(): Promise<void> {
        return new Promise((resolve) => {
            LsLiveChat.initLivePersonJs();
            resolve();
        });
    }

    protected static initLivePersonJs(): void {
        LsLiveChat.window.lpTag = LsLiveChat.window.lpTag || {};

        if (typeof LsLiveChat.window.lpTag._tagCount === "undefined") {
            LsLiveChat.window.lpTag = {
                site: '2623979',
                section: LsLiveChat.window.lpTag.section || "",
                tagletSection: LsLiveChat.window.lpTag.tagletSection || null,
                autoStart: LsLiveChat.window.lpTag.autoStart !== false,
                ovr: LsLiveChat.window.lpTag.ovr || {},
                _v: "1.8.0",
                _tagCount: 1,
                protocol: "https:",
                events: {
                    bind: function (t: any, e: any, i: any) {
                        LsLiveChat.window.lpTag.defer(() => {
                            LsLiveChat.window.lpTag.events.bind(t, e, i);
                        }, 0);
                    },
                    trigger: function (t: any, e: any, i: any) {
                        LsLiveChat.window.lpTag.defer(() => {
                            LsLiveChat.window.lpTag.events.trigger(t, e, i);
                        }, 1);
                    }
                },
                defer: function (t: Function, e: number) {
                    if (e === 0) {
                        this._defB = this._defB || [];
                        this._defB.push(t);
                    } else if (e === 1) {
                        this._defT = this._defT || [];
                        this._defT.push(t);
                    } else {
                        this._defL = this._defL || [];
                        this._defL.push(t);
                    }
                },
                load: function (t?: string, e?: string, i?: string) {
                    const self = this;
                    setTimeout(() => {
                        self._load(t, e, i);
                    }, 0);
                },
                _load: function (t?: string, e?: string, i?: string) {
                    let url = t;
                    if (!t) {
                        url = this.protocol + "//" + (this.ovr && this.ovr.domain ? this.ovr.domain : "lptag.liveperson.net") + "/tag/tag.js?site=" + this.site;
                    }

                    const script = document.createElement("script");
                    script.setAttribute("charset", e || "UTF-8");
                    if (i) script.setAttribute("id", i);
                    script.setAttribute("src", url);
                    script.setAttribute("async", "true");
                    script.setAttribute("defer", "true");

                    document.getElementsByTagName("head")[0].appendChild(script);
                },
                init: function () {
                    this._timing = this._timing || {};
                    this._timing.start = new Date().getTime();
                    const self = this;

                    if (LsLiveChat.window.attachEvent) {
                        LsLiveChat.window.attachEvent("onload", () => self._domReady("domReady"));
                    } else {
                        LsLiveChat.window.addEventListener("DOMContentLoaded", () => self._domReady("contReady"), false);
                        LsLiveChat.window.addEventListener("load", () => self._domReady("domReady"), false);
                    }

                    if (typeof LsLiveChat.window._lptStop === "undefined") {
                        this.load();
                    }
                },
                start: function () {
                    this.autoStart = true;
                },
                _domReady: function (t: string) {
                    if (!this.isDom) {
                        this.isDom = true;
                        this.events.trigger("LPT", "DOM_READY", { t });
                    }
                    this._timing[t] = new Date().getTime();
                },
                vars: LsLiveChat.window.lpTag.vars || [],
                dbs: LsLiveChat.window.lpTag.dbs || [],
                ctn: LsLiveChat.window.lpTag.ctn || [],
                sdes: LsLiveChat.window.lpTag.sdes || [],
                hooks: LsLiveChat.window.lpTag.hooks || [],
                ev: LsLiveChat.window.lpTag.ev || []
            };

            LsLiveChat.window.lpTag.init();
        } else {
            LsLiveChat.window.lpTag._tagCount += 1;
        }
    }

    protected getLivePerson = () => {
        if (LsLiveChat.$livePerson === undefined) {
            const $lp = $(LsLiveChatOptions.livePersonSelector);
            if ($lp.length > 0) {
                LsLiveChat.$livePerson = $lp;
            }
        }
        return !!LsLiveChat.$livePerson;
    }

    protected showLivePerson = () => {
        LsLiveChat.$livePerson.trigger("click");
    }

    protected logError = () => {
        LsLogger.trace(`LivePerson [${LsLiveChatOptions.livePersonSelector}] not found`);
    }
}

export default LsLiveChat;